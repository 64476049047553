.index-services {
	background: url(/images/map-bg.png) center 0;
	color: #fff;
	padding-top: 66px;

	&__items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		font-size: 24px;
	}

	&__item {
		width: 25%;
		text-align: center;
		margin-bottom: 90px;

		&:before {
			content: '';
			display: block;
			width: 100%;
		}

		&:hover {
			color: #88ca6e;
		}

		&_svh {
			&:before {
				height: 162px;
				background: url('/images/index-services/svh.png') center 0 no-repeat;
				margin-bottom: 45px;
			}

			&:hover:before {
				background-position-y: -162px;
			}
		}

		&_custom {
			&:before {
				height: 183px;
				background: url('/images/index-services/custom.png') center 0 no-repeat;
				margin-bottom: 25px;
			}

			&:hover:before {
				background-position-y: -183px;
			}
		}

		&_declaration {
			&:before {
				height: 162px;
				background: url(/images/index-services/declaration.png) no-repeat center 0;
				margin-bottom: 46px;
			}

			&:hover:before {
				background-position-y: -162px;
			}
		}

		&_control {
			&:before {
				height: 159px;
				background: url(/images/index-services/control.png) no-repeat center 0;
				margin-bottom: 48px;
			}

			&:hover:before {
				background-position-y: -159px;
			}
		}

		&_load {
			&:before {
				height: 158px;
				background: url(/images/index-services/load.png) no-repeat center 0;
				margin-bottom: 36px;
			}

			&:hover:before {
				background-position-y: -159px;
			}
		}

		&_cargo {
			&:before {
				height: 91px;
				background: url(/images/index-services/cargo.png) no-repeat center 0;
				margin-bottom: 54px;
				margin-top: 50px;
			}

			&:hover:before {
				background-position-y: -91px;
			}
		}

		&_consult {
			&:before {
				height: 134px;
				background: url(/images/index-services/consult.png) no-repeat center 0;
				margin-bottom: 60px;
			}

			&:hover:before {
				background-position-y: -134px;
			}
		}

		&_transit {
			&:before {
				height: 157px;
				background: url(/images/index-services/transit.png) no-repeat center 0;
				margin-bottom: 38px;
			}

			&:hover:before {
				background-position-y: -157px;
			}
		}

		@media (max-width: $screen-md-max) {
			width: 50%;
		}

		@media (max-width: $screen-sm-max) {
			width: 100%;
		}
	}


}
