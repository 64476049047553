body {
	background-color: #f7f7f7;
	font-family: $metronic;
	-webkit-font-smoothing: antialiased;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.container {
	margin-right: auto;
	margin-left: auto;
	max-width: 1280px;

	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		&_padding {
			padding-right: 16px;
			padding-left: 16px;
		}
	}

	@media (max-width: $screen-sm-max) {
		&_padding {
			padding-right: 8px;
			padding-left: 8px;
		}
	}
}

a {
	color: inherit;
	text-decoration: none;
	transition: color .3s ease;
}

.heading-title {
	font-size: 36px;
	text-align: center;
	color: inherit;
	margin-bottom: 82px;
}