.contacts {
	padding-top: 39px;
	overflow: hidden;
	padding-bottom: 100px;

	&__title {
		font-size: 36px;
		text-align: center;
		margin-bottom: 48px;
	}

	&__carousel {
		max-width: 490px;
		width: 100%;
		float: left;
	}

	&__info {
		float: right;
		text-align: right;
		padding-top: 36px;
	}

	&__requisites {
		text-align: left;
		font-size: 21px;
		margin-bottom: 150px;

		td {
			padding: 13px 0px 9px 15px;
		}
	}

	@media (max-width: $screen-xs-max) {
		&__carousel {
			float: none;
			margin-bottom: 30px;
		}
		
		&__info {
			float: none;
		}

		&__requisites {
			font-size: inherit;
			margin-bottom: 20px;
		}
	}
}