.index-about {
	padding-top: 39px;
	padding-bottom: 50px;
	overflow: hidden;

	&__title {
		font-size: 36px;
		text-align: center;
		margin-bottom: 34px;
	}

	&__subtitle {
		font-size: 30px;
		margin-bottom: 16px;
	}

	&__gallery {
		width: 50%;
		float: right;
		margin-left: 20px;
		margin-bottom: 46px;
	}

	&__text {
		font-size: 18px;
		line-height: 1.5em;
		text-align: justify;
	}

	&__more {
		text-align: right;

		a {
			color: #40702d;
			font-size: 24px;
		}
	}

	p {
		margin-bottom: 15px;
	}
}