.index-gallery {
	overflow: hidden;
	padding-top: 62px;
	padding-bottom: 70px;

	&__carousel {
		max-width: 1190px;
		width: 100%;
		margin: 0 auto;
	}

	&__title {
		text-align: center;
		font-size: 37px;
		margin-bottom: 32px;
	}
}