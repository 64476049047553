.topline {
	position: relative;
	background-color: $body-color;

	&__container {
		@include clearfix;
		padding-top: 54px;
	}

	&__contact-info {
		float: right;
		font-size: 24px;
	}
	
	&__contact {
		display: block;

		&:before {
			content: '';
			@include inline-block(baseline);
			margin: -3px 0 -3px;
			margin-right: 7px;
			height: 18px;
		}
	}

	&__contact_phone {
		text-decoration: none;
		margin-bottom: 21px;

		&:before {
			// @include sprite($sprite-phone);
			width: 17px;
			background: url('/images/phone.png') center no-repeat;
		}
	}

	&__contact_email {
		text-decoration: none;

		&:before {
			width: 17px;
			background: url('/images/email.png') center no-repeat;
		}
	}

	&__logo {
		float: left;
		margin-top: -34px;
		width: 172px;
	}

	&__slogan {
		max-width: 618px;
		text-align: center;
		-webkit-font-smoothing: antialiased;
		font-size: 31px;
		text-transform: uppercase;
		font-weight: bold;
		margin: 0 auto;
		line-height: 37px;
	}

	@media (max-width: $screen-md-max) {
		&__container {
			padding-top: 15px;
		}

		&__slogan {
			margin-top: 5px;
			font-size: 25px;
			width: 500px;
		}

		&__logo {
			margin-top: 0;
			width: 115px;
		}

		&__contact {

			&-info {
				font-size: 20px;
				margin-top: 15px;
			}

			&_phone {
				margin-bottom: 10px;
			}
		}
	}

	@media (max-width: 833px) {
		&__slogan {
			font-size: 20px;
			width: 380px;
			line-height: 22px;
			margin-top: 15px;
		}

		&__contact {

			&-info {
				font-size: 15px;
				margin-top: 10px;
			}
		}
	}

	@media (max-width: $screen-sm-max) {
		display: none;

		&_sidebar { display: block; }
	}


}