.layout-header {
	$navbar-height: 52px;

	position: relative;
	padding-bottom: 73px;//$navbar-height;

	&__navbar {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: $navbar-height;
		background: #40702d;
		color: #fff;
		font-size: 18px;
		z-index: 100;

		&.affix {
			position: fixed;
			top: 0;
		}

		&-logo {
			display: none;
		}

		@media (max-width: $screen-sm-max) {
			&-logo {
				display: block;
				text-align: center;

				img {
					margin-left: auto;
					margin-right: auto;
					width: 69px;
					margin-top: 5px;
				}
			}
		}

	}

	&__navbar-items {
		display: none;
		float: right;
		padding: 18px 16px;
	}

	&__navbar-item_search {

	}

	@media (max-width: $screen-md-max) {
		padding-bottom: $navbar-height;
	}
}