.article {
	margin-top: 39px;
	margin-bottom: 70px;
	line-height: 1.5em;
	font-size: 18px;
	text-align: justify;

	&__title, h1 {
		font-size: 35px;
		text-align: center;
		margin-bottom: 55px;
	}

	&__content {
		margin-bottom: 50px;
	}

	&__carousel {
		max-width: 900px;
		width: 100%;
		margin: 0 auto;
	}

	ol, ul {
		list-style-position: inside;
		margin-bottom: 20px;

		li {
			margin-bottom: 15px;
		}
	}

	ol {
		list-style-type: decimal;
	}

	ul {


		li {
			position: relative;
			padding-left: 13px;
		}
		li:before {
			content: '';
			background-color: #40702d;
			border-radius: 5px;
			width: 5px;
			height: 5px;
			display: block;
			position: absolute;
			left: -1px;
			top: 8px;
		}
	}

	h2 {
		font-size: 25px;
		font-weight: bold;
	}

	h3 {
		font-size: 25px;
	}

	h2, h3 {
		margin-bottom: 17px;
	}

	p {
		margin-bottom: 15px;
	}

	strong {
		font-weight: bold;
	}
}