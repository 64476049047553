.services {
	padding-top: 39px;

	&__title {
		text-align: center;
		font-size: 36px;
		margin-bottom: 23px;
	}

	&__articles {
		position: relative;
		z-index: 2;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 30px;

		&:after {
			content: '';
			display: block;
			width: 352 / 1110 * 100%;
			height: 0;
		}
	}

	&__service {
		width: 50%;
		overflow: hidden;
		font-size: 16px;
		margin-bottom: 0;
		margin-top: 0;

		&:nth-child(odd) {
			background: even
		}

		a {
			border: 1px solid transparent;
			padding: 53px 0 53px 0px;
			display: block;

			&:hover {
				background: #ffffff;
				cursor: pointer;
				border: 1px solid #d3d3d3;
			}
		}

		&-img-wrap {
			width: 250px;
			display: inline-block;
			font-size: 50px;
			line-height: 188px;
			text-align: center;
			vertical-align: top;
		}

		&-img {
			vertical-align: middle;
		}

		h1 {
			font-size: 24px;
			margin-bottom: 11px;
			text-align: left;

			&:hover a {
				color: #40702d;
			}
		}

		&-texts {
			padding-right: 20px;
			display: inline-block;
			width: 360px;
		}

		@media (max-width: 1250px) {
			&-texts {
				padding-right: 0;
				width: 235px;
			}
		}

		@media (max-width: $screen-lg-min) {
			&-img-wrap {
				width: 100%;
				display: block;
			}

			h1 {
				text-align: center;
			}

			&-texts {
				width: 100%;
				padding: 0 20px;
			}
		}

		@media (max-width: $screen-sm-max) {
			width: 100%;

			&-img-wrap {
				width: 100%;
				float: none;
			}

			&-texts {
				padding-right: 0;
				margin-left: 0;
			}
		}
	}
}