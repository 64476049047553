$metronic: 'Metronic Pro Cond', Verdana, sans-serif;
$body-color: #f7f7f7;

// Media query breakpoints
$screen-xs-min: 320px;
$screen-sm-min: 468px;
$screen-md-min: 720px;
$screen-lg-min: 1024px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$bold: 700;
$normal: 400;
$light: 300;