.price {
	padding-top: 39px;

	&__title {
		font-size: 35px;
		text-align: center;
		margin-bottom:50px;
	}

	&__article {
		padding-top: 50px;
		border-bottom: 2px solid #8f8f8f;
		padding-bottom: 50px;
		margin-top: 0;
		margin-bottom: 0;

		&:nth-child(odd) {
			background-color: #efeff0;
		}

		&:nth-child(even) {
			background-color: #f7f7f7;
		}

		&-img {
			float: left;
			margin-right: 30px;
		}

		h1 {
			font-size: 25px;
			margin-bottom: 20px;
			text-align: left;
		}

		&-header {
			overflow: hidden;
			margin-bottom:30px;
		}

		table {
			width: 100%;
			border: 2px solid #000;
			margin-bottom: 20px;

			tr:first-child td {
				background: #40702d;
				color: #fff;
			}

			td {
				padding: 10px;
				border: 1px solid #000;
			}
		}

		@media (max-width: $screen-xs-max) {
			&-img {
				float: none;
				margin-bottom: 15px;
			}
		}
	}
}