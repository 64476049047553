$placeholder-color: #ccc;
.form {
	input,
	textarea {
		margin: 0;
		border-radius: 0;
		border: 1px solid $body-color;
		box-sizing: border-box;
		font-family: inherit;
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
		background-color: rgba(255, 255, 255, 0.2);
		color: $body-color;
	}

	::-webkit-input-placeholder {color:$placeholder-color;}
	::-moz-placeholder          {color:$placeholder-color;}/* Firefox 19+ */
	:-moz-placeholder           {color:$placeholder-color;}/* Firefox 18- */
	:-ms-input-placeholder      {color:$placeholder-color;}

	input[type='checkbox'] {
		vertical-align: .05em;
	}

	input[type='text'],
	input[type='password'],
	input[type='phone'],
	input[type='email'],
	textarea {
		width: 100%;
		padding: 7px 10px 4px;
	}

	button[type='submit'],
	input[type='submit']{
		cursor: pointer;
	}

	.has-error {
		input[type='text'],
		input[type='password'],
		input[type='phone'],
		input[type='email'],
		textarea {
			border-color: red;
		}

		.help-block {
			margin: 2px 0 0;
			color: $body-color;
		}
	}

	.hint-block {
		margin: 2px 0 0;
	}

	textarea {
		display: block;
		min-height: 131px;
		resize: vertical;
	}

	&__table {
		display: table;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	&__row {
		display: table-row;
	}

	&__cell {
		display: table-cell;
		padding: 15px 0 0;
		width: 50%;

		.help-block,
		.hint-block {
			font-size: 12px;
		}
	}

	&__row:first-child &__cell {
		padding-top: 0;
	}

	&__row &__cell:first-child &__twice-input-wrapper {
		width: 95%;
	}

	&__cell_label {
		width: 1%;
		padding-right: 30px;
		text-align: right;
		white-space: nowrap;
	}

	&__row.required &__cell_label label {
		&:after {
			content: '*';
			float: right;
			margin-right: -10px;
			color: red;
		}
	}

	&__row.has-error &__cell_label label {
		color: $body-color;
	}

	&__subscribe-checkbox {
		font-weight: $normal;
	}

	&__row_subscribe &__cell_label {
		padding-top: 20px;
	}

	&__address-group {
		display: flex;

		input {
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: 1%;
			text-align: center;

			&[name='street'] {
				flex-basis: 35%;
				text-align: left;
			}
		}

		.error {
			border: 1px solid $body-color;
		}

		input + input { margin-left: 10px; }
	}

	&__email-phone-group {
		display: flex;

		input {
			flex-grow: 1;
			flex-shrink: 1;
			margin-left: 10px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&_checkout &__cell_label {
		min-width: 165px;
	}

	&_register &__cell_label {
		min-width: 160px;
	}

	&_register &__row_submit &__cell {
		padding-top: 25px;
	}

	&_profile &__table {
		width: 100%;
		max-width: 690px;
		margin: 0;
	}

	&_profile &__cell_label {
		text-align: left;
		min-width: 230px;
	}

	@media (max-width: $screen-md-max) {
		&__table {
			width: 100%;
		}

		&_profile &__cell_label {
			min-width: 0;
		}
	}
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		&__cell_label {
			font-size: 14px;
		}
	}

	@media (max-width: $screen-sm-max) {
		&__table, &__row, &__cell {
			display: block;
		}

		&__table {
			input[type='text'],
			input[type='password'],
			input[type='email'],
			textarea {
				padding-right: 15px;
				padding-left: 15px;
			}
		}

		&__row &__cell:first-child &__twice-input-wrapper {
			width: 100%;
		}

		.form__cell {
			width: 100%;
			margin-bottom: 15px;
		}
	}
}
