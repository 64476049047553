$space: -50px;
.owl-svh-theme {
	.owl-nav {
		[class*='owl-'] {
			$size: 48px;


			position: absolute;
			top: 50%;
			margin-top: -$size / 2;
			//@include taf-diagonal-bg;
			color: #fff;

			&:after {
				display: inline-block;
				width: 42px;
				content: "";
				background-image: url(/images/gallery-arrows.png);
				height: 42px;
			}
		}

		.owl-prev {
			left: $space;

			&:hover:after {
				background-position: 0 -42px;
			}

			&:active:after {
				background-position: 0 -84px;
			}
		}

		.owl-next {
			right: $space;
			text-align: right;

			&:after {
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				transform: rotate(180deg);
			}

			&:hover:after {
				background-position: 0 -42px;
			}

			&:active:after {
				background-position: 0 -84px;
			}
		}

		.disabled {
			opacity: .5;
			cursor: default;
		}

		@media (max-width: $screen-xs-max) {
			.owl-prev {
				left: 10px;
			}

			.owl-next {
				right: 10px;
			}
		}
	}

	.owl-item.active span {
		border: 1px solid #40702d;
		display: block;
		padding: 4px;
	}

	.owl-dots {
		position: absolute;
		right: 0;
		bottom: -46px;
		left: 0;
		text-align: center;

		.owl-dot {
			@include inline-block;
			padding: 5px 5px 5px 4px;

			span {
				display: block;
				width: 17px;
				height: 17px;
				border-radius: 50%;
				background: #808080;
			}

			&.active span {
				background: #40702d;
			}
		}
	}
}