.layout-footer {
    background: url(/images/map-bg.png) no-repeat center center;
    padding-top: 60px;

    &__feedback {
        display: table-row;

        &-wrap {
            margin-bottom: 80px;
            display: table;
            max-width: 828px;
            margin-left: auto;
            margin-right: auto;
        }

        &-img {
            display: table-cell;
            width: 37%;
            padding: 0 10px;

            img {
                border: 2px solid $body-color;
                box-sizing: border-box;
            }

            &-text {
                text-align: center;
                margin-top: 24px;
                font-size: 18px;
                color: $body-color;
            }
        }

        &-form {
            display: table-cell;
            vertical-align: top;
            padding-left: 20px;
            padding-top: 4px;
        }

        &-title {
            color: $body-color;
            margin-bottom: 16px;
        }

        &-name-phone {
            margin-bottom: 21px;
        }

        &-submit {
            background-color: $body-color;
            border: none;
            font-family: $metronic;
            float: right;
            font-size: 20px;
            padding: 9px 46px 7px;
            line-height: 24px;

            &:hover {
                background-color: #fff;
            }

            &:active {
                background-color: #cac4c4;
            }
        }

        &-textarea {
            margin-bottom: 23px;
        }

        &-success {
            display: none;
        }

        &-success_visible {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            font-size: 31px;
            color: #f7f7f7;
        }

        @media (max-width: $screen-xs-max) {
            &-wrap {

            }

            &-img, &-form {
                display: block;
                width: 100%;
            }

            &-form {
                padding-left: 0;
            }

        }
    }

    &__copyright {
        text-align: center;
        background: rgba(96, 139, 80, 0.5);
        padding: 10px 10px 5px;
        color: #fff;
    }


}