@font-face {
	font-family: 'Metronic Pro Cond';
	src: url('/fonts/metronicprocondbold.eot');
	src: url('/fonts/metronicprocondbold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/metronicprocondbold.woff2') format('woff2'),
	url('/fonts/metronicprocondbold.woff') format('woff'),
	url('/fonts/metronicprocondbold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Metronic Pro Cond';
	src: url('/fonts/metronicprocondregular.eot');
	src: url('/fonts/metronicprocondregular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/metronicprocondregular.woff2') format('woff2'),
	url('/fonts/metronicprocondregular.woff') format('woff'),
	url('/fonts/metronicprocondregular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Metronic Pro Cond';
	src: url('/fonts/metronicprocondlight.eot');
	src: url('/fonts/metronicprocondlight.eot?#iefix') format('embedded-opentype'),
	url('/fonts/metronicprocondlight.woff2') format('woff2'),
	url('/fonts/metronicprocondlight.woff') format('woff'),
	url('/fonts/metronicprocondlight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}