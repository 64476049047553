.layout-sidebar {
	$animation-duration: .3s;
	$navigation-delim-color: #575757;
	$navigation-padding-right: 9px;
	$navigation-padding-left: 16px;
	$layout-header-height: 52px;

	&__backdrop {
		position: fixed;
		top: $layout-header-height;
		left: 0;
		width: 100%;
		height: 100%;
		padding-bottom: 100px;
		margin-bottom: -100px;
		background: #000;
		opacity: .41;
		z-index: 120;
		transition: opacity $animation-duration ease;
	}

	&__content {
		position: fixed;
		top: $layout-header-height;
		left: 0;
		width: 500px;
		height: 100%;
		height: calc(100% + 100px);
		box-sizing: border-box;
		padding-top: 0px;
		padding-bottom: 100px;
		margin-bottom: -100px;
		background: $body-color;
		box-shadow: 2px 0 10px 0 rgba(#000, .6);
		z-index: 120;
		transition: left $animation-duration ease;
	}

	&__content-inner {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	&__navigation {
		padding: 6px 0;
		color: #000;
		font-size: 18px;

		li {
			position: relative;
			margin-top: 1px;
		}

		> ul > li:first-child {
			margin-top: 0;

			a:before { border-top-width: 0; }
		}
		> ul > li > ul > li > a { font-weight: bold; }
	}

	&__navigation-items {
		padding-left: 18px;
		overflow: hidden;
	}

	&__navigation-toggle,
	&__navigation-heading {
		padding: 11px $navigation-padding-right 7px $navigation-padding-left;
		cursor: pointer;
	}

	&__navigation-heading {
		position: relative;
		display: block;

		&:before {
			content: '';
			position: absolute;
			top: -1px;
			right: $navigation-padding-right;
			bottom: -1px;
			left: $navigation-padding-left;
			display: block;
			border: solid $navigation-delim-color;
			border-width: 1px 0;
		}
	}

	&__navigation-toggle {
		position: absolute;
		top: 0;
		right: 0;
		width: 32px;
		padding-left: 0;
		outline: none;
		color: $body-color;
		text-align: center;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 4px;
			bottom: 3px;
			left: 0;
		}

		&:after { content: '\00a0'; }

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -$navigation-padding-right/2;
			transform: translate(-50%, -50%);
			transition: transform .3s ease;

			&:before {
				//@extend %red-arrow;
				display: block;
				font-size: 24px;
			}
		}

		&.ui-accordion-header-active span {
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}

	&_collapsed {}

	&_collapsed &__backdrop {
		opacity: 0;
		visibility: hidden;
		transition: opacity $animation-duration ease, visibility 0s linear $animation-duration;
	}

	&_collapsed &__content {
		left: -510px;
		visibility: hidden;
		transition: left $animation-duration ease, visibility linear $animation-duration;
	}

	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		&__content { width: 386px; }

		&_collapsed &__content { left: -396px; }
	}

	@media (max-width: $screen-xs-max) {
		&__content { width: 266px; }

		&_collapsed &__content { left: -276px; }
	}
}
