.schedules {
	padding-top: 39px;

	&__title {
		text-align: center;
		font-size: 36px;
		margin-bottom: 53px;
	}

	&__items {
		position: relative;
		z-index: 2;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 100px;

		&:after {
			content: '';
			display: block;
			width: 352 / 1110 * 100%;
			height: 0;
		}
	}

	&__item {
		width: 49%;
		margin-bottom: 20px;
		position: relative;

		&-img {
			width: 100%;
			display: block;
		}

		&-bg {
			position: absolute;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			left: 0;
			right: 0;
			padding: 20px 0;
		}

		&:hover &-bg {
			background: rgba(0, 0, 0, 0.3);
		}

		&-title {
			color: #bdbfc2;
			text-align: center;
		}

		&:hover &-title {
			color: #ffffff;
		}
	}

	@media (max-width: $screen-xs-max) {
		&__item {
			width: 100%;
		}
	}
}