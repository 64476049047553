.navigation {
	@include clearfix;
	float: left;

	a {
		color: inherit;
		text-decoration: none;
	}

	&__toggle,
	&__item > a,
	&__dropdown-item > a {
		display: block;
		transition: background-color .3s ease;

		&:hover, &.ui-state-active {
			background: $body-color;
			color: #000;
		}

		//&:active { background: $brand-active-color; }
	}

	&__toggle {
		display: none;
		float: left;
		position: relative;
		width: 52px;
		height: 52px;
		margin-left: 0px;
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 23%;
			display: block;
			width: 24px;
			height: 3px;
			margin: -1px 0 0 0px;
			border-radius: 1px;
			background: #fff;
			box-shadow: 0 -8px 0 0 #fff, 0 8px 0 0 #fff;
		}
	}

	&__items {
		@include clearfix;
	}

	&__item {
		padding-top: 3px;
		float: left;

		> a {
			display: block;
			padding: 18px 12px 10px;
		}

		&:first-child { margin-left: -9px; }
	}

	&__dropdown {
		position: absolute;
		display: none;
		min-width: 196px;
		background: #181818;
		font-size: 18px;
		font-weight: $light;
	}

	&__dropdown-item {
		> a {
			display: block;
			padding: 12px 20px 9px;
		}
	}

	&__columns {
		display: flex;
	}

	&__column {
		min-width: 196px;
	}

	&__column-heading {
		padding: 20px 20px 13px;
		font-weight: $normal;

		a {
			&:hover {
				color: $body-color;
			}
		}
	}

	&__dropdown_columns {}


	@media (max-width: $screen-md-max) {
	}

	@media (max-width: 768px) {
		&__toggle { display: block; }

		&__items { display: none !important; }
	}
}