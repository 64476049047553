.breadcrumb {
	font-size: 21px;
	margin-top: 36px;

	.breadcrumb__item {
		display: inline-block;
		margin-right: 9px;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: top;
			margin: 1px 10px -1px 0;
			background-image: url(/images/breadcrumb-arrow.png);
			width: 10px;
			height: 11px;
		}

		&:first-child:before {
			display: none;
		}
	}

	.breadcrumb__item_active {
		color: #40702d;
	}
}